import React, {useState, useEffect} from "react";
import {
    getDisplayDuration,
    useMutateByRegex,
    useUpdateSearchParams,
    duplicateSearchParamsSubset,
    getDateFormat,
    capitalizeFirstLetter
} from "../../../utility/Utilities";
import {useLoginSession} from "../../../stores/loginSession";
import {useFeedbackMessage} from "../../../stores/FeedbackMessage";
import { useBackend } from "../../../utility/Backend";
import { useCheckIsAdmin } from "../../../utility/UserGroups";
import { useCompilation } from "../../../stores/compilation";
import Backend from "../../../utility/Backend";
import EditVideoMetadata from "./EditVideoMetadata";
import ConfirmModal from "../../../components/ConfirmModal";
import Permission from "./Permission";
import Config from "../../../utility/Config";
import EmbedCodeButton from "./EmbedCodeButton";
import { channelToTeamPageSuffix } from "../../../utility/teamIdToTeamPageSuffix";
import { useSWRConfig } from "swr";
import PaymentMetadata from "./PaymentMetadata";
import {FaTrashAlt} from "react-icons/fa";
import {FiEdit3} from "react-icons/fi";
import {TiGroup} from "react-icons/ti";
import { HiOutlineShare } from "react-icons/hi";
import { IoCalendarSharp } from "react-icons/io5";
import { IoMdArrowDropright } from "react-icons/io";
import "./MetadataSection.css"
import "./VideoStatistics.css"
import VideoStatistics from "./VideoStatistics";
import { VscGraph } from "react-icons/vsc";
import classNames from "classnames";
import ShareVideo from "../../../components/ShareVideo";
import { ImBlocked } from "react-icons/im";
import moment from "moment";

function GetStreamData (streamId) {
    const {token} = useLoginSession();
    const apiQuery = {access_token: token, include_key: true}
    const {data: streamData} = useBackend("/live_ingest/" + streamId, apiQuery);
    if (!streamData) return null
    return streamData 
}

function ListTagInformation ({tags}) {
    if (tags.length === 0) return "-"
    return tags.map((tag, idx) => {
        const action = tag.action
        if (!action) return "" // Malformed tag - should be unreachable
        const props = []
        for (const [key, obj] of Object.entries(tag)) {
            if (key !== "action") props.push(
                <div key={props.length} className="video-metadata-tags-prop">
                    <div>
                        {capitalizeFirstLetter(key)}
                    </div>
                    <div>:</div>
                    <div>{obj?.value || "-"}</div>
                </div>
            )
        }
        return (
            <div key={idx} className="video-metadata-tag">
                <span>{(Config.categories[action] || action).toUpperCase()}</span>
                <div className={props.length !==0 ? "video-metadata-tags-cont" : ""}>
                    {props}
                </div>
            </div>
        )
    })
}

export default function VideoMetadata ({
    playlist,
    openNoPermissionModal=null,
    hasEditPermission=false,
    hasGrantPermission=false,
    livestreamId=null,
}) {

    const {
        id,
        events,
        channels,
        description,
        thumbnail_url,
        game,
        creator,
        duration_ms,
        rating,
        date,
        is_private,
        video_url
    } = playlist;

    const {cache} = useSWRConfig();

    const displayDuration = getDisplayDuration(duration_ms);
    const recordingTimestamp = events[0]?.recording_timestamp;
    const payment = playlist.payment

    const {token, allowPlaylistEditPermissions} = useLoginSession();
    const {playlistId, clearCompilation} = useCompilation()
    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();
    const mutateByRegex = useMutateByRegex()
    const {showFeedback} = useFeedbackMessage();
    const [isFsAdmin,] = useCheckIsAdmin()

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false)
    const [viewStatistic, setViewStatistic] = useState(false)

    const editMetaParam = searchParams.get("edit_metadata")
    const streamId = searchParams.get("stream");
    const stream = streamId ? GetStreamData(streamId) : null

    const isSef = Config.association === "SEF"
    const isNtf = Config.association === "NTF"
    const isToppserien = Config.association === "toppserien"
    const hasChannels = playlist.channels?.length > 0
    const isLive = playlist.is_live

    const navigableFrontendLink = isSef || isNtf || isToppserien
    // TODO further check when other league or even all will need highlights match link
    const showMatchHighlightsLink = !!playlist.game && navigableFrontendLink

    useEffect(() => {
        if (editMetaParam) setIsEditModalOpen(true)
    }, [editMetaParam])

    const query = {access_token: token}
    const {data: asset} = useBackend("/asset/" + playlist.events[0].video_asset_id, query)

    const allTags = events.reduce((list, e) => list.concat(e.tags), [])
    const categories = <ListTagInformation tags={allTags} />

    // TODO Temporary fix for stream without category
    const isPrePostRoll = Array.isArray(playlist.events[0].tags) && playlist.events[0].tags && playlist.events.some((e) => 
        e.tags?.some((t) => ["placeholder", "preroll", "postroll"].includes(t.action)))

    const isEditMetadataAllowed = isFsAdmin || (hasEditPermission && !isPrePostRoll)
    const isEditPermissionAllowed = isFsAdmin || (allowPlaylistEditPermissions && !isPrePostRoll)
    const leagueWithStatistics = ["SEF", "NTF", "toppserien", "SFL"]
    const showStatistic = leagueWithStatistics.includes(Config.association)

    let webHref = Config.webHref
    const baseName = window.location.pathname.split("/")[1];
    const search = duplicateSearchParamsSubset(["editing"], searchParams);
    const matchUrl = `/${baseName}/library/match/${playlist?.game?.id}${search}`

    const onClickMatchLinkSetCache = () => {
        cache.set("viewed_match", playlist.game.id)
    }

    const matchLink = game ? (
        <div className="match-link-game">
            <div className="match-link-game-info">
                <div className="match-link-game-date">
                    <IoCalendarSharp className=""/>
                    {getDateFormat(game.date)}
                </div>
                <img src={game.home_team.logo_url} alt="team logo" />
                {game.home_team_goals} - {game.visiting_team_goals}
                <img src={game.visiting_team.logo_url} alt="team logo" />
            </div>
            <a 
                href={matchUrl}
                onClick={onClickMatchLinkSetCache}
                className="match-link-to-match">
                To match
                <IoMdArrowDropright/>
            </a>
        </div>
    ) : (
        <div>-</div>
    )


    let club = playlist.channels? channelToTeamPageSuffix(playlist.channels[0]) : ""
    const hasFrontendPage = ["SEF", "NTF", "Toppserien"].includes(Config.association)

    let frontendHref = playlist.frontend_url
    if (isSef) {
        frontendHref = `${webHref}/${club}playlist/${playlist.id}`
        if (livestreamId) {
            frontendHref = `${webHref}/${club}livestream/${livestreamId}`
        }
    }

    const frontendUrlLink = (
        <div className="frontend-url-link">
            {navigableFrontendLink ? (
                <a href={frontendHref} target="blank">{frontendHref}</a>
            ) : (
                <div>{frontendHref}</div>
            )}
            {navigableFrontendLink && (
                <div className="frontend-url-share">
                    <div className="frontend-url-share-btn">
                        <HiOutlineShare/>
                        Share
                    </div>
                    <ShareVideo playlist={playlist} url={playlist.frontend_url}/>
                </div>
            )}
        </div>
    )
    
    // TODO better handling for NTF obosligaen link
    if (isNtf && baseName === "obosligaen") webHref = "https://highlights.obos-ligaen.no"
    const matchHighlightsUrl = showMatchHighlightsLink && `${webHref}/game/${playlist?.game?.id}`

    const highlightsMatchLink = (
        <div className="frontend-url-link">
            <a href={matchHighlightsUrl} target="blank">{matchHighlightsUrl}</a>
            <div className="frontend-url-share">
                <div className="frontend-url-share-btn">
                    <HiOutlineShare/>
                    Share
                </div>
                <ShareVideo playlist={playlist} url={matchHighlightsUrl}/>
            </div>
        </div>
    )

    function deletePlaylist () {
        Backend.delete("/playlist/" + id, {access_token: token})
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error);
                    showFeedback("warning", error);
                } else {
                    resetAllSearchParamsExcept(["season", "videotype", "page"]);
                    showFeedback("success", "Video deleted successfully");
                    console.log("delete success");
                    mutateByRegex(/^\/playlist\//)
                }
            })
        // Clear the compilation editing if the deleted video is on editing mode    
        if (playlist.id === playlistId) clearCompilation()
    }

    const editMetadata = () => {
        if (!hasEditPermission) openNoPermissionModal()
        else setIsEditModalOpen(true)
    }

    const deleteVideo = () => {
        if (!hasEditPermission) openNoPermissionModal()
        else setIsDeleteModalOpen(true)
    }

    const openPermissionModal = () => {
        if (!hasGrantPermission) openNoPermissionModal()
        else setIsPermissionModalOpen(true)
    }

    const cancelEditMetadata = () => {
        if (editMetaParam) resetAllSearchParamsExcept(["editing"])
        setIsEditModalOpen(false)
    }

    const behindHoldbackMessage = !playlist.is_available_publicly && (
        <div className="metadata-holdback-message">
            <ImBlocked /> Holdback until {moment(playlist.public_release_timestamp).format("LLL")}
        </div>
    )
    
    return (
        <>
            {isEditModalOpen && (
                <EditVideoMetadata 
                    playlist={playlist} 
                    asset={asset} 
                    onClose={cancelEditMetadata}
                    payment={payment}
                    stream={stream}
                    />
            )}
            {isDeleteModalOpen && (
                <ConfirmModal
                    isOpen
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={deletePlaylist}
                    cancelText="Cancel"
                    confirmText="Delete">
                    <div className="confirm-icon-message">
                        <div className="confirm-icon"><FaTrashAlt/></div>
                        <div className="confirm-title">Are you sure you wish to delete {description} video?</div>
                    </div>
                </ConfirmModal>
            )}
            {isPermissionModalOpen && (
                <Permission
                    playlist={playlist}
                    hasGrantPermission={hasGrantPermission}
                    onClose={() => setIsPermissionModalOpen(false)}/>
            )}

            <div className="data-info-cont">
                <div className="data-info-head-title">
                    Video Metadata
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="data-info">
                        <div className="data-info-title">Title</div>
                        <div className="data-info-value">{description}</div>
                    </div>
                    {hasFrontendPage && (
                        <div className="data-info">
                            <div className="data-info-title">{isSef ? "Fotboll Play" : "Highlights video URL"}</div>
                            <div className="data-info-value">{frontendUrlLink}</div>
                        </div>
                    )}
                    {hasFrontendPage && (
                        <div className="data-info">
                            <div className="data-info-title">Match highlights URL</div>
                            <div className="data-info-value">{showMatchHighlightsLink ? highlightsMatchLink : "-"}</div>
                        </div>
                    )}
                    {isSef && (
                        <div className="data-info">
                            <div className="data-info-title">Embedding</div>
                            <div className="data-info-value"><EmbedCodeButton playlistId={playlist.id} /></div>
                        </div>
                    )}
                    <div className="data-info">
                        <div className="data-info-title">Match</div>
                        <div className="data-info-value">{matchLink}</div>
                    </div>
                    {(isSef || hasChannels) && (
                        <div className="data-info">
                            <div className="data-info-title">Channels</div>
                            <div className="data-info-value">{(channels && channels.length !== 0) ? channels.map(c => c.name).join(", ") : "-"}</div>
                        </div>
                    )}
                    <div className="data-info">
                        <div className="data-info-title">Categories</div>
                        <div className="data-info-value">{categories}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Thumbnail</div>
                        <img src={thumbnail_url} alt="thumbnail" className="vm-thumbnail"/>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Duration</div>
                        <div className="data-info-value">{displayDuration}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Rating</div>
                        <div className="data-info-value">{rating}</div>
                    </div>
                    {showStatistic && (
                        <div className="data-info column">
                            <div className="data-info-title-and-value">
                                <div className="data-info-title">Video statistics</div>
                                <div className="data-info-value">
                                    <div className="video-total-views">
                                        Total views : {playlist.view_count}
                                    </div>
                                    <button 
                                        onClick={() => setViewStatistic(!viewStatistic)} 
                                        className={classNames("video-statistics-view", {"view": viewStatistic})}
                                        >
                                        <VscGraph className="graph-icon"/>
                                        {viewStatistic ? "Close" : "View"} graphs
                                    </button>
                                </div>
                            </div>
                            {viewStatistic && <VideoStatistics playlist={playlist} viewStatistic={viewStatistic}/>}
                        </div>
                    )}
                    <div className="data-info">
                        <div className="data-info-title">Created by</div>
                        <div className="data-info-value">{creator.name}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Created</div>
                        <div className="data-info-value">{getDateFormat(date, true)}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Time of recording</div>
                        <div className="data-info-value">{getDateFormat(recordingTimestamp, true)}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Published</div>
                        <div className="data-info-value">{is_private? "No" : "Yes"}</div>
                    </div>
                    {isFsAdmin && (
                        <div className="data-info">
                            <div className="data-info-title">Video URL</div>
                            <div className="data-info-value">{video_url}</div>
                        </div>
                    )}
                    {/*<div className="data-info">*/}
                    {/*    <div className="info-title">Featured</div>*/}
                    {/*    <div className="">{featured? "Yes" : "No"}</div>*/}
                    {/*</div>*/}
                    {isSef && <PaymentMetadata playlist={playlist} stream={stream}/>}
                    <div className="confirm-cancel-btn-cont between margin-top">
                        <div className="metadata-and-permission-btn">
                            {isEditMetadataAllowed && (
                                <button onClick={editMetadata}>
                                    <span><FiEdit3 className="icon-in-btn"/> Edit metadata</span>
                                </button>
                            )}
                            {isEditPermissionAllowed && (
                                <button onClick={openPermissionModal}>
                                    <span><TiGroup className="icon-in-btn"/> Edit permissions</span>
                                </button>
                            )}
                        </div>
                        {isEditMetadataAllowed && (
                            <button type="button" disabled={isLive} className="red-btn" onClick={deleteVideo}>
                                <span><FaTrashAlt className="icon-in-btn"/> Delete video</span>
                            </button>
                        )}
                    </div>
                </form>
                {behindHoldbackMessage}
            </div>
        </>
    );
}